<template>
  <div class="box">
    <div class="top" @click="toVideo">
      <img :src="info.coverImg" class="top" />
      <img
        src="@/img/course/player.png"
        class="player"
        @click="
          toVideo(info.list[0].courseContentId, info.list[0].courseContentPath)
        "
      />
    </div>
    <div class="nr">
      <div class="title">{{ info.courseName }}-{{ info.courseInfo }}</div>
      <div class="flex-box">
        <div>
          共<span>{{ info.allNum }}期</span>,已更新<span>{{
            info.currentNum
          }}</span
          >期
        </div>
        <div>{{ info.viewNum }}人已学</div>
      </div>
      <van-tabs v-model="active" color="#113FC4" title-active-color="#113FC4">
        <van-tab title="内容">
          <div v-html="info.detailText"></div>
        </van-tab>
        <van-tab title="目录">
          <div class="list-title">贷款课程</div>
          <div
            class="list"
            v-for="l in info.list"
            :key="l.courseId"
            @click="toVideo(l.courseContentId, l.courseContentPath)"
          >
            <div class="list-left">
              <img :src="l.contentImg" alt="" class="list-img" />
              <img
                src="@/img/course/lock.png"
                class="lock"
                v-if="l.isLimitVip && userInfo.vip != 1"
              />
            </div>
            <div class="list-right">
              <div class="list-name">{{ l.contentName }}</div>
              <div class="list-hint">
                {{ l.contentDetail }}
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import url from "@/service/url-config";
export default {
  data() {
    return {
      info: {},
      active: 0,
      userInfo: {},
    };
  },
  created() {
    this.$http.post(url.getCourseDetail + "?courseId=1").then((res) => {
      this.info = res.data;
    });
    //获取用户信息
    this.$http.get(url.userInfo).then((res) => {
      this.userInfo = res.data;
    });
  },
  methods: {
    toVideo(courseContentId, courseContentPath) {
      if (!courseContentPath) {
        return;
      }
      this.$router.push({
        path: "courseInfo",
        query: {
          courseContentId: courseContentId,
          courseContentPath: courseContentPath,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #ffffff;
  padding-top: 66px;
  padding-bottom: 50px;
  .top {
    width: 375px;
    height: 205px;
    position: relative;
    .player {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      margin-top: -25px;
      left: 50%;
      margin-left: -25px;
    }
  }
  .nr {
    width: calc(100% - 36px);
    margin: 0 auto;
    .title {
      color: #101010;
      font-weight: bold;
      font-size: 20px;
      margin-top: 11px;
      margin-bottom: 14px;
    }
    .flex-box {
      display: flex;
      justify-content: space-between;
      div {
        color: #999999;
        font-size: 12px;
        span {
          color: #113fc4;
          font-size: 14px;
        }
      }
    }
    .list-title {
      color: #101010;
      font-size: 15px;
      font-weight: bold;
    }
    .list {
      display: flex;
      margin-top: 17px;
      .list-left {
        width: 120px;
        height: 73px;
        border-radius: 10px;
        overflow: hidden;
        background: lightblue;
        position: relative;
        .list-img {
          position: absolute;
          width: 120px;
          height: 73px;
          top: 0;
          left: 0;
        }
        .lock {
          position: absolute;
          width: 120px;
          height: 73px;
          top: 0;
          left: 0;
        }
      }
      .list-right {
        width: 203px;
        margin-left: 7px;
        .list-name {
          font-size: 13px;
          color: #101010;
          font-weight: bold;
        }
        .list-hint {
          font-size: 9px;
          color: #999999;
        }
      }
    }
  }
}
</style>
